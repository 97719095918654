.monitoring-accounts-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.monitoring-table-head {
  th {
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
  }
}