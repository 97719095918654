.container-labeler-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.container-labeler-main {
  border-radius: 1px;
  padding: 40px 20px;
  margin-top: -17px;
  margin-bottom: 30px;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.container-labeler-label {
  transform: translate(20px 50px);
  .labeler-label {
    display: inline-block;
    padding: 5px 15px;
    margin-left: 5px;
    width: fit-content;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transform: translateY(-20px);
  }
}

.label-custom-text {
  white-space:nowrap;
}
