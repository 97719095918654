.liquidation-settings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
}

.info-text {
  background: rgba(13, 40, 90, 0.5);
  color: white;
  padding: 2px 7px;
  font-size: 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  margin-left: 3px;
  transform: translateY(-10px);
}
