.each-validator-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  padding: 20px;
  max-height: 200px;

  .each-validator-content__header-container {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid darkgray;
    width: 150px;
  }
}


.bridge-validators {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px,1fr));
  max-height: 400px;
}

.bridge-validators-balance {
margin-top: 5px;
  .bridge-validators-balance__value {
    font-size: 20px;
    font-weight: 500;
    color: darkslategrey;
  }
}


.bridge-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
}

.bridge-sub-body {
  border-top: 2px solid #0D295D;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 99;
}

.bridge-tabs-container {
  max-height: 50px;
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;

  button {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: none;
    transform: translate(0, 10px);
    border: 2px solid gray;
    font-weight: 500;
    letter-spacing: 2px;
    border-radius: 2px 2px 0 0;
    transition: 0.4s ease-in-out;
  }

  button:hover {
    background: #DDDEFF;
  }

  .bridge-tabs-refresh {
    margin-left: auto;
    border-radius: 3px 3px 0 0;

    i {
      transform: scale(1.3);
    }
  }

}

.active-tab {
  background: #0D295D !important;
  color: white;
}

.bridge-info-container {
  padding: 0 20px;
}

.skeleton-validator-container {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(600px, 1fr));

  .skeleton-validator-items, {
    margin: 10px;
  }
}

.skeleton-fields-container {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(600px, 1fr));

  .skeleton-fields-items {
    margin: 10px 10px 20px 10px;
  }
}

.disabled-button {
  opacity: 0.4 !important;
  pointer-events: none;
}

.inc-net-container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-content: center;

  .inc-net-body {
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    padding: 50px;
    background: url("https://appv2.rampdefi.com/assets/images/hero_banner@2x.png");
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  span {
    font-weight: 200;
  }

  .switch-button {
    padding: 0 20px;
    max-width: 350px;
    height: 45px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: white;
    color: #0D295D;
    opacity: 0.3;
  }
}
