.web-app-container {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(500px,1fr));
  justify-content: center
}

.web-app-container_items {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.web-app-load {
  position: absolute;
  transform: scale(0.15);
  left: 190px;
  top: -90px;
}