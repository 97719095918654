.main-layout {
  min-height: 100vh;
  position: relative;
}

.main-layout_protocol-component {
  width: calc(100vw - 380px);
  max-width: calc(100vw - 380px);
}

.main-drawer-container {
  max-width: 380px;
}

.main-layout-container {
  display: flex;
  flex-direction: row;
}

.main-content {
  margin: auto;
  max-width: 1366px;
  margin-top: 100px;
  min-height: 100vh;
  position: relative;
}

.loading-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@keyframes ldio-qmca8kj37i {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-qmca8kj37i div {
  position: absolute;
  animation: ldio-qmca8kj37i 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #0d285a;
  transform-origin: 80px 82px;
}
.loadingio-spinner-eclipse-80ful76r3w3 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
  transform: scale(0.5);
}
.ldio-qmca8kj37i {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-qmca8kj37i div { box-sizing: content-box; }

.loading-text {
  position: absolute;
  bottom: 340px;

  h6 {
    letter-spacing: 2px;
    font-weight: 300;
  }
}


.header-test {
  height: 60px;
  width: 100%;
  background: url("https://appv2.rampdefi.com/assets/images/hero_banner@2x.png");
  margin-bottom: 50px;
  position: fixed;
  z-index: 99999999;

  .header-test-sub {
    display: flex;
    height: 100%;
    align-items: center;
    max-width: 1666px;
    margin: auto;

    h4 {
      color: white;
      margin: 0;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 1.3px;
    }
  }

}

.main-layout-column {
  background: #F2F2F2;
}

.demo-text-style {
  margin-left: 3px;
  background: #CE9809;
  color: #212529;
  padding: 1px 5px;
  border-radius: 3px;
  font-weight: 600;
  letter-spacing: 2px;
  transform: translate(-14px, -10px) scale(0.7, 0.7);
}

