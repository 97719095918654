.table {
  min-width: 1000px;
  max-height: 850px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.table table,
table tbody,
table td,
table th,
table tr {
  border: 1px solid black;
}

.table table td:first-child,
table thead {
  position: sticky;
  background-color: #ddd;
}

.table table td:first-child {
  left: 0;
  z-index: 1;
}

.table table thead {
  top: 0;
  z-index: 2;
}

.table th,
td {
  padding: 5px;
}

.table td {
  min-width: 250px;
}

.table th {
  text-align: center;
}

.table tbody tr:nth-child(odd) {
  background: #fff;
}

.table tbody tr:nth-child(even) {
  background: #eee;
}
