.input-number-string {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  position: relative;
}



.input-disabled {
  background: #EAEAEA;
  outline: none;
  pointer-events: none;
}

.checkbox-input {
  cursor: pointer;
}

.input-dropdown {

  .input-dropdown-toggle {
    background: white;
    border: 1px solid #ced4da;
    color: #212529;
  }

  span {
    padding-right: 10px;
  }

}

.input-string-container {
  position: relative;
}

.input-string-container::after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background: #232334;
  position: absolute;
  top: 0;
  right: 0;
  animation: rotate360 linear infinite 3s;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

