.strategy-field-container {
  position: relative;
}

.refresh-button {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -20px;
  background: none;
  border: #0D295D 1px solid;

  i {
    color: #0D295D;
  }
}

.refresh-button:hover, .refresh-button:active {

  i {
    color: white;
  }
}

.strategy-list-item {
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
}

.strategy-list-container {
  padding-top: 12px;
  position: relative;
}

.strategy-list-group-container {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 500px;
}

.strategy-list-title {

  .strategy-list-id {
    display: block;
    font-size: 13px;
    opacity: 0.6;
    letter-spacing: 2px;
  }

  .strategy-list-name {
    display: block;
    font-weight: 400;
    letter-spacing: 1px;
  }
}

.no-selected-strategy-window {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 24px;
    opacity: 0.4;
    letter-spacing: 1px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}