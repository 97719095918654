.messageContainer {
  position: fixed;
  right: 10%;
  top: 90px;
  width: 500px;
  z-index: 999999999 !important;
}

.message {
  border-radius: 5px;
  opacity: 0.9;
  padding: 7px;
  margin: 5px;
  animation: messageFade 0.4s ease-in-out;

  h5 {
    font-weight: 700;
    font-size: 18px;
  }

  p {
    font-size: 13px;
    max-width: 90%;
    word-break: normal
  }
}

.message-success {
  background:darkgreen;

  h5, p, .exit-button i {
    color: white
  }

  .exit-button {
    background: none;
    outline: none;
    border: none;
    i {
      font-size: 18px;
      color: white;
    }
  }
  .exit-button:hover {
    background: white;
    i {
      color: $success !important;
    }
  }
}

.message-info {
  background: #EEDA0B;

  h5, p {
    color: black
  }

  .exit-button {
    background: none;
    outline: none;
    border: none;
    i {
      font-size: 18px;
      color: black;
    }
  }
  .exit-button:hover {
    background: white;
    i {
      color: #EEDA0B !important;
    }
  }
}

.message-error {
  background: $danger;

  h5, p {
    color: white
  }

  .exit-button {
    background: none;
    outline: none;
    border: none;
    i {
      font-size: 18px;
      color: white;
    }
  }
  .exit-button:hover {
    background: white;
    i {
      color: $danger !important;
    }
  }
}

.message-warning {
  background: #CE9809;

  .exit-button {
    background: none;
    outline: none;
    border: none;
    i {
      font-size: 18px;
      color: white;
    }
  }
  .exit-button:hover {
    background: white;
    i {
      color: #CE9809 !important;
    }
  }
}

@keyframes messageFade {
  from {
   opacity: 0;
  }
  to {
    opacity: 1;
  }
}