@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

@import "style/theme";
@import "utils/MessageProvider/messageProvider";
@import "components/Drawer/drawer";
@import "layouts/mainLayout";
@import "page/authenticationPage";
@import "page/Protocol/protocol";
@import "page/Explorer/explorer";
@import "components/container-labeler";
@import "components/Input/input";
@import "page/Protocol/WebAppSetting/webAppSettings";
@import "utils/ConfigProvider/configProvider";
@import "components/ScanLink/scan-link";
@import "page/Bridge/bridge";
@import "page/Monitoring/monitoring";
@import "page/Tools/Liquidation/liquidationSettings";

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,a,p,label,span,button,div {
  font-family: "Rubik", sans-serif;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

