.explorer-container {
  display: flex;
  flex-direction: column;
}

.explorer-strategy-toggle-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  .explorer-strategy-toggle-group_label {
    margin: 0;
  }

  .dropdown-toggle {
    background: white;
    color: #232334;
    border: #232334 1px solid;
  }
}

.search-container {
}

.custom-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-container-block-items {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
}

.table-events-item {
  font-weight: 500;
}

.no-events-window {
padding-top: 20px;
}
