.auth-main {
  background: url("https://appv2.rampdefi.com/assets/images/hero_banner@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.authentication-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out !important;
  background: rgba(255,255,255,0);


  .authentication-ramp-logo {
    margin-top: -140px;
    filter: brightness(0) invert(1);
  }

  .banner-background {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgb(255,255,255);

    .banner-front {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 512px;
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);    }
  }

  .authentication-details {
    display: flex;
    flex-direction: column;
    text-align: center;

    div h4 {
      font-size: 34px;
      font-weight: 300;
      margin-bottom: 70px;
      letter-spacing: 3px;
    }

    .connect-button {
      background: none;
      border: 1px white solid;
      font-size: 20px;
      letter-spacing: 2px;
      color: white;
      text-transform: uppercase;
      transition: 0.4s ease-in-out;
      z-index: 999;

      .metamask-icon {
        width: 25px;
        margin-right: 10px;
      }
    }

    .connect-button:hover {
      background: white;
      color: #CE9809;
    }
  }
}