.rowFilter {
  max-height: 500px;
  overflow-y: scroll;
  min-width: 300px;
}

.item {
  padding: 10px;
  margin-left: 20px;
  min-width: 400px;
}

.item:hover {
  background: #f2f4f7;
  cursor: pointer;
}
