.ramp-logo {
  width: 200px;
}

.wallet-address {
  font-size: 13px;
}

.drawer-logo {
  height: 210px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-dashboards {
  display: flex;
  flex-direction: column;
  width: 100%;

  .drawer-dashboards_heading {
    color: gray;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
  }

  .drawer-dashboards_link {
    width: 100%;
    text-decoration: none;
    color: black;
  }
}

.drawer-wallet-infos {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  height: 120px;

  .drawer-wallet-infos_heading {
    margin-bottom: 25px;
    color: gray;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
  }

  .drawer-wallet-infos_items {
    margin-left: 15px;

    .drawer-wallet-infos_items_title {

    }
  }
}

.drawer-inner-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;

  .background-drawer {
    background: url("https://appv2.rampdefi.com/assets/icon/ramp.png") no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    width: 200px;
    height: 200px;
    z-index: 999999;
  }

}


.drawerContainer {
  background: none;
  box-shadow: 16px 0 25px #C7C7C7, -16px 0 10px #F9F9F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
}

.list-group-item {
  padding: 10px 0;
}

.drawer-demo-span {
  background: #0D295D;
  color: white;
  padding: 2px 8px;
  margin-left: 1px;
  border-radius: 8px;
  display: inline-block;
  transform: scale(0.8);
}